* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'sans';
}

:root {
  --main-color: cyan;
}

@font-face {
  font-family: 'sans';
  src: url('./px.ttf');
}

body {
  width: 100vw;
  font-family: monospace;
}

button {
  padding: 15px 35px;
  border-radius: 100px;
  font-size: 30px;
  position: fixed;
  cursor: pointer;
  color: var(--main-color);
  background: none;
  border: none;
  z-index: 10;
  bottom: 50px;
  border: solid 1.5px var(--main-color);
  backdrop-filter: blur(4px);
  /*  box-shadow: 0 0 25px cornflowerblue;*/
}

#background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  /*  background: cornflowerblue;*/
  background: radial-gradient(circle, black, white);
}

::-webkit-scrollbar {
  display: none;
}
